import { useClassList } from '../utils/useProps';
import type { JSXElement} from 'solid-js';
import { Match, Switch } from 'solid-js';

/**
 * Spin 类
 * @class Spin
 * @constructor
 */
export interface SpinProps {
    classList?: any,
    class?: string,
    style?: any,
    type?: 'pulse'|'oval'|'gear'|'dot',
    title?: string|JSXElement,
    size?: number | 'small' | 'large'
}

export function Spin (props: SpinProps) {
    const classList = () => useClassList(props, 'cm-spin-wrap', {
        [`cm-spin-${props.size}`]: props.size && typeof props.size === 'string'
    });
    const type = () => props.type || 'dot';
    return (
        <div classList={classList()}>
            <div class="cm-spin-inner">
                <div class="cm-spin" style={{width: props.size + 'px', height: props.size + 'px', ...props.style}}>
                    <Switch>
                        <Match when={type() === 'pulse'}>
                            <div class="cm-spin-pulse" />
                        </Match>
                        <Match when={type() === 'oval'}>
                            <svg class="cm-spin-oval" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38 38" stroke="#2d8cf0">
                                <g fill="none" fill-rule="evenodd">
                                    <g transform="translate(1 1)" stroke-width="2">
                                        <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                        <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(113.635 18 18)">
                                            <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </Match>
                        <Match when={type() === 'gear'}>
                            <svg class="cm-spin-gears" width="32px" height="32px" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <g transform="translate(50 50)">
                                    <g transform="translate(-19 -19) scale(0.6)">
                                        <g transform="rotate(177)">
                                            <animateTransform attributeName="transform" type="rotate" values="0;360"
                                                keyTimes="0;1" dur="2s" begin="0s" repeatCount="indefinite"
                                            />
                                            <path fill="#20a0ff"
                                                d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7
                                            L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268
                                            L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154
                                            L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346
                                            A38 38 0 0 1 7.0000000000000036 37.3496987939662
                                            L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662
                                            L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1
                                            -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435
                                            L-28.531545636048154 38.431040572659825 L-38.43104057265982
                                            28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1
                                            -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007
                                            L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964
                                            L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435
                                            -21.460477824182675 L-31.35997276079435 -21.460477824182675
                                            L-38.431040572659825 -28.531545636048147 L-28.53154563604818
                                            -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1
                                            -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662
                                            L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662
                                            L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686
                                            -31.359972760794342 L21.460477824182686 -31.359972760794342
                                            L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818
                                            L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662
                                            -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                            />
                                        </g>
                                    </g>
                                    <g transform="translate(19 19) scale(0.6)">
                                        <g transform="rotate(160.5)">
                                            <animateTransform attributeName="transform" type="rotate" values="360;0"
                                                keyTimes="0;1" dur="2s" begin="-0.125s" repeatCount="indefinite"
                                            />
                                            <path fill="rgba(12.549019607843137%,62.74509803921568%,100%,0.382)"
                                                d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7
                                            L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268
                                            L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154
                                            L28.531545636048154 38.431040572659825
                                            L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036
                                            37.3496987939662 L7.0000000000000036 37.3496987939662
                                            L7.000000000000004 47.3496987939662 L-6.999999999999999
                                            47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1
                                            -21.46047782418268 31.35997276079435 L-21.46047782418268
                                            31.35997276079435 L-28.531545636048154 38.431040572659825
                                            L-38.43104057265982 28.531545636048158 L-31.359972760794346
                                            21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007
                                            L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008
                                            L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997
                                            A38 38 0 0 1 -31.35997276079435 -21.460477824182675
                                            L-31.35997276079435 -21.460477824182675
                                            L-38.431040572659825 -28.531545636048147
                                            L-28.53154563604818 -38.4310405726598
                                            L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992
                                            -37.3496987939662 L-6.999999999999992 -37.3496987939662
                                            L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662
                                            L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686
                                            -31.359972760794342 L21.460477824182686 -31.359972760794342
                                            L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818
                                            L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662
                                            -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </Match>
                        <Match when={type() === 'dot'}>
                            <div class="cm-spin-dot">
                                <div class="cm-spin-dot-point" />
                                <div class="cm-spin-dot-point" />
                                <div class="cm-spin-dot-point" />
                                <div class="cm-spin-dot-point" />
                            </div>
                        </Match>
                    </Switch>
                </div>
                <div class="cm-spin-text">{props.title ?? 'loading...'}</div>
            </div>
        </div>
    );
}
